.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

#header {
  background: rgb(3, 72, 73);
  background-image: url(http://quickdemo.threekit.com/threekit_logo_inverted.svg);
  background-repeat: no-repeat;
  background-size: auto 30px;
  background-position-y: center;
  background-position-x: 30px;
}

.App-link {
  color: #09d3ac;
}
